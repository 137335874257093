// This is About component, It will contain about us info.
import React from "react";
import "./Terms.css"

const Terms = () => {
  return <div id="terms-container">
      <h1 id="header">Terms of Service</h1>
      <h3 className="terms-header">NON-REFUNDABLE DIAGNOSTIC FEE</h3>
      <p className="terms-text">We charge a non-refundable service fee (the “Diagnostic Fee”) for all non-warranty visits to Customer’s residence or business. The Diagnostic Fee will be quoted to you prior to our visit. The fee covers costs incurred by us for travel and the performance of diagnostic services by a trained Major Appliance Repair technician. By accepting the Service Order, you acknowledge and agree to a) to pay the Diagnostic Fee in full whether Major Appliance Repair performs recommended repairs or not; and b) that the Diagnostic Fee will be credited toward the cost to repair in the event that Customer agrees to have Major Appliance Repair perform the recommended repair or replacement.</p>
      <h3 className="terms-header">ESTIMATE</h3>
      <p className="terms-text">The estimate section of the Service Order summarizes the costs for the parts and service we estimate are required to repair your appliance(s) (the “Price Quote”). By accepting the Service Order, you acknowledge that you agree to the terms of the Price Quote and the agreed upon scope of work. If applicable, sales tax is included in the Price Quote.</p>
      <h3 className="terms-header">PAYMENT TERMS AND COLLECTION COSTS</h3>
      <p className="terms-text">Payment is due and will be collected upon completion of the services unless we agree otherwise specifically in writing. Payment may be made by check or credit card. Any dishonored check or bank draft will be charged a processing fee of $25.00. You agree to pay all expenses incurred by Major Appliance Repair for the collection of any unpaid amounts including, but not limited to all attorney’s fees, filing fees and costs. Past due amounts shall bear interest at the annual rate of 18%, or the maximum otherwise allowed by law, whichever is less.</p>
      <h3 className="terms-header">LIMITED WARRANTY</h3>
      <p className="terms-text">For repairs, parts and service provided by us, we warrant to you that the repairs, parts and service we provide to you will be free from defects in material and workmanship. The duration of this warranty is thirty (30) days from as applicable (i) the date of your receipt of the part, or (ii) the date of repair or service. If during this thirty-day period you discover a defect in the repairs, parts or service you must promptly notify us in writing. Major Appliance Repair's obligation for defective parts and/or workmanship, and Customer’s exclusive remedy, shall be limited, at Major Appliance Repair's option, to the replacement of any defective parts or workmanship or the refund of amounts paid by Customer for said the service and parts. All warranty claims are subject to inspection and approval by Major Appliance Repair.
The foregoing warranties do not apply in any manner to the following: a) repair or replacement of any appliances used for any commercial or industrial application; b) cosmetic or non-functional parts, including but not limited to glass, trim, knobs, panels, racks, tanks, tubs, baskets, structural parts, doors, door liners and shelves; c) plumbing supply water lines to any appliance, whether replaced or recommended for replacement by Major Appliance Repair; d) repair of any appliance that has been moved from the location at which it was originally repaired or installed by Major Appliance Repair; or e) repairs on appliances for which parts are no longer available from the original manufacturer. Additionally, Major Appliance Repair shall be under no legal obligation to honor any warranty set forth herein unless Customer has paid all amounts owed under the applicable Price Quote.</p>
      <h3 className="terms-header">ENTIRE AGREEMENT</h3>
      <p className="terms-text">These General Terms and Conditions of Service and each Service Order constitute the complete agreement (“Agreement”) between Major Appliance Repair and Customer and supersede all prior or contemporaneous agreements or representations, written or oral.</p>
      <h3 className="terms-header">DAMAGE WAIVERS</h3>
      <p className="terms-text">It is the responsibility of the customer to familiarize themselves with waivers we have in place surrounding the provision of any services we provide. We only provide services under these waivers and our price is based on the customer accepting our waivers. By requesting that we perform the work, you are accepting our waivers. If one or part of these waivers is found to not be enforceable that will not prevent the remaining waivers from being enforced.</p>
<h3 className="terms-subheader">Waiver of Damages When Moving Appliance</h3>
<p className="terms-text">You understand there is inherent risk in when moving appliances. There is risk of damage to the machine itself, and to areas surrounding its installation, including but not limited to flooring, cabinetry and counter tops. You acknowledge the technician is not required to move the appliance. In exchange for his willingness to do so, you promise to hold harmless both the technician and Major Appliance Repair from any and every liability associated with any such damage as may occur in connection with this movement.</p>
<h3 className="terms-subheader">Waiver of Damages When Working with Water</h3>
<p className="terms-text">You understand the machinery that’s being worked on is connected with the building’s plumbing system, and whenever any machinery is so connected, there is an inherent risk that component failure, imperfect connections and/or other faults could result in unintended leakage and/or flooding within the building, causing significant and sometimes catastrophic damage. Excepting for cases of gross, clearly proven, and explicitly identifiable negligence by the technician, you promise to hold harmless both the technician and Major Appliance Repair, its principals, agents and employees from any and all damages, harms or liability that may result from such an occurrence. By allowing the technician to commence work, you explicitly waive the right to any contrary claim.</p>
<h3 className="terms-subheader">Waiver of Damages from Failed Cooling Equipment</h3>
<p className="terms-text">You understand that, like any machinery, refrigeration equipment consists of a multiplicity of complex components, any of which can fail without warning, and that any effort to service and/or repair same carries inherent risks. Diagnoses are not always straightforward, and, even when carefully performed, repair this equipment and/or service work may involve imperfections that result in failure of the machinery to perform as intended. Such failures may lead to food spoilage, discomfort, inconvenience, and (particularly in commercial contexts) even loss of revenue as associated with canceled or failed events, inability to service customers, etc. You acknowledge that, by undertaking to service and/or repair your equipment, Major Appliance Repair is not accepting responsibility for any such risks. You agree all such risks are your own (and/or your company’s) alone, and you agree to hold harmless and exempt from liability Major Appliance Repair, it’s agents and employees from any and all such harms. By allowing the technician to commence work, you explicitly waive the right to any contrary claim.</p>
<h3 className="terms-subheader">Waiver of Damages from Lifting Glass Cook Top</h3>
<p className="terms-text">You understand that, in lifting a glass/ceramic cook top surface to access components below it, there is an inherent risk of breakage. The top is attached with strong adhesive, and it is not always possible to achieve separation without the surface fracturing. While expecting the technician to exercise every due level of care, you nevertheless recognize that the Technician and Major Appliance Repair explicitly refrain from accepting any responsibility for these risks. You agree all such risks are your own (and/or your company’s) alone, and you agree to hold harmless and exempt from Major Appliance Repair, it’s agents and employees from any and all such responsibility. By allowing the technician to commence work, you explicitly waive the right to any contrary claim.</p>
  </div>
};

export default Terms;
